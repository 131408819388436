import React from "react";
import PropTypes from "prop-types";
import { useContent } from "fusion-content";
import { useAppContext } from "fusion-context";
import get from "lodash.get";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";
import HeaderNavLinks from "../hpHeaderNav/_children/HeaderNavLinks";
import contentFilter from "../../../../content/filters/site-subs-offer";
import navContentFilter from "../../../../content/filters/site-navigation";
import accountContentFilter from "../../../../content/filters/site-accountbar";

const useHomePageNavData = () => {
  return useContent({
    source: "site-service-navigation",
    query: { hierarchy: "WebNavBar" },
    filter: navContentFilter
  });
};

export const useNavData = () => {
  const accountSideBarData = useContent({
    source: "site-account-sidebar",
    filter: accountContentFilter
  });

  const { globalContent } = useAppContext();

  // TODO do we need to have it fall back to something for homepage?
  const arcId = get(globalContent, "_id");

  const ctaOfferData = useContent({
    source: "site-homepage",
    filter: contentFilter
  }) || { site: {} };

  const sideNavData = useContent({
    source: "site-menu",
    query: { hierarchy: "WebNav" },
    filter: navContentFilter
  });

  const sideNavFeaturedData = useContent({
    source: "site-menu",
    query: { hierarchy: "WebNavFeatured" },
    filter: navContentFilter
  });

  if (sideNavData && accountSideBarData) {
    return {
      accountSideBarData,
      arcId,
      ctaOfferData,
      sideNavData,
      sideNavFeaturedData
    };
  }

  return null;
};

const SiteNav = () => {
  const { isAdmin } = useAppContext();
  const { isNoNav } = useAssemblerContext();

  /**
   * NOTE: useScrollState starts listening at
   * the small bp, which enables the subsequent
   * animations on the logo and subs buttons.
   */

  const data = useNavData();
  const inlineLinkData = useHomePageNavData();

  if (!data) return null;
  if (isNoNav) return null;

  const navLinks =
    inlineLinkData && inlineLinkData.children
      ? inlineLinkData.children.map((child) => {
          return {
            name: child?.navigation?.nav_title,
            url: child?.site?.site_url
          };
        })
      : null;

  // The wrapper is used to ensure the nav's stickiness in the admin
  // since it does not want to have a parent div.
  return React.createElement(
    isAdmin ? `div` : React.Fragment,
    isAdmin
      ? { style: { transform: "translateY(0)", overflow: "hidden" } }
      : null,
    <div className="hpgrid-max-width ma-auto center">
      <HeaderNavLinks inlineLinkData={navLinks} />
    </div>
  );
};

SiteNav.propTypes = {
  data: PropTypes.object,
  isFixed: PropTypes.bool
};

export default SiteNav;
